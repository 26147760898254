<template>
  <div class="kefu">
    <div v-if="!isRequest" class="kefu-warp">
      <Header title="我的客服" />
      <div class="mianBox" v-if="kefuUrl">
        <iframe class="iframe" :src="kefuUrl" frameborder="0"></iframe>
      </div>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import { getService } from '@/api/user';
import Loading from '@/components/Loading';
import Header from '@/components/Header';

export default {
  data() {
    return {
      kefuUrl: '',
      isRequest: false,
    };
  },
  components: {
    Loading,
    Header,
  },
  created() {
    this.getService();
  },
  methods: {
    async getService() {
      this.isRequest = true;
      try {
        let res = await this.$Api(getService);
        this.isRequest = false;
        if (res.code == 200) {
          let baseUrl = this.$store.getters.baseUrl;
          let url = baseUrl.slice(0, baseUrl.length - 1);
          this.kefuUrl = url + res.data;
        }
      } catch (e) {
        this.isRequest = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.kefu {
  height: 100%;

  .kefu-warp {
    height: 100%;
  }

  .mianBox {
    width: 100%;
    height: calc(100% - 44px);

    .iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
